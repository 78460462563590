@import "styles-bootstrap";

/* Importing Bootstrap SCSS file. */

// @import("~bootstrap/scss/bootstrap");
// /* Importing Datepicker SCSS file. */
// @import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
@import '~ngx-toastr/toastr.css';

body {
    font-size: 22px;
}

// common margins paddings etc...
.m-0 {
    margin: 0 !important;
}

.m-l-0 {
    margin-inline: 0 !important;
}

.m-r-0 {
    margin-inline: 0 !important;
}

.m-t-0 {
    margin-top: 0 !important;
}

.m-b-0 {
    margin-bottom: 0 !important;
}

.m-y-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.m-x-0 {
    margin-inline: 0 !important;
    margin-inline: 0 !important;
}

.m-5 {
    margin: 5px !important;
}

.m-l-5 {
    margin-inline: 5px !important;
}

.m-r-5 {
    margin-inline: 5px !important;
}

.m-t-5 {
    margin-top: 5px !important;
}

.m-b-5 {
    margin-bottom: 5px !important;
}

.m-y-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.m-x-5 {
    margin-inline: 5px !important;
    margin-inline: 5px !important;
}

.m-15 {
    margin: 15px !important;
}


.m-l-15 {
    margin-inline: 15px !important;
}

.m-r-15 {
    margin-inline: 15px !important;
}

.m-t-15 {
    margin-top: 15px !important;
}

.m-b-15 {
    margin-bottom: 15px !important;
}

.m-y-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.m-x-15 {
    margin-inline: 15px !important;
    margin-inline: 15px !important;
}

.m-t-19 {
    margin-top: 19px !important;
}

.m-b-19 {
    margin-bottom: 19px !important;
}

.m-l-30 {
    margin-inline: 30px !important;
}

.m-r-30 {
    margin-inline: 30px !important;
}

.m-t-30 {
    margin-top: 30px !important;
}

.m-b-30 {
    margin-bottom: 30px !important;
}

.p-0 {
    padding: 0 !important;
}
.p-2 {
    padding: 2px !important;
}
.p-3 {
    padding: 3px !important;
}
.p-l-0 {
    padding-left: 0 !important;
}

.p-r-0 {
    padding-right: 0 !important;
}

.p-t-0 {
    padding-top: 0 !important;
}

.p-b-0 {
    padding-bottom: 0 !important;
}

// .p-5 {
//     padding: 5px !important;
// }


.p-l-5 {
    padding-left: 5px !important;
}

.p-r-5 {
    padding-right: 5px !important;
}

.p-t-5 {
    padding-top: 5px !important;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.p-y-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.p-x-5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.p-15 {
    padding: 15px !important;
}


.p-l-15 {
    padding-left: 15px !important;
}

.p-r-15 {
    padding-right: 15px !important;
}

.p-t-15 {
    padding-top: 15px !important;
}

.p-b-15 {
    padding-bottom: 15px !important;
}

.p-y-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.p-x-15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.p-15 {
    padding: 15px !important;
}

.b-r-5 {
    border-radius: 5px !important;
}

.txt-center {
    text-align: center !important;
}

.txt-right {
    text-align: right !important;
}

.txt-left {
    text-align: left !important;
}

.h-100pcnt {
    height: 100% !important;
}

.w-100pcnt {
    width: 100% !important;
}

.f-w-300 {
    font-weight: 300 !important;
}

.f-w-400 {
    font-weight: 400 !important;
}

.f-w-600 {
    font-weight: 600 !important;
}


.mat-drawer-container {
    background-color: #ffffff !important;
}

.mat-drawer.mat-sidenav {
    .list-item-active {
        font-weight: bolder;
        color: #fdb831 !important;
        background: #fff5de !important;
    }

    .mat-drawer-inner-container {
        .mat-list-base {
            .mat-list-item {
                .mat-list-item-content {
                    padding: 0 25px;
                }
            }
        }
    }
}

//General css styles--------------->>
.flx-column {
    display: flex;
    flex-direction: column;
}

//Icons css styles--------------->>
.cs-icon-primary {
    height: 20px;
    width: 20px;
    margin: 1px 5px;
}

.cs-icon-primary-trial {
    height: 40px;
    width: 40px;
    margin: 1px 5px;
}

.cs-icon-btn {
    height: 24px;
    width: 24px;
    margin: 1px 5px;
    filter: invert(78%) sepia(42%) saturate(7466%) hue-rotate(4deg) brightness(153%) contrast(115%);
}

.cs-chip-btn {
    height: 24px;
    width: auto;
    margin: 2px 4px;
}

//Menu Icons Style--->>

//

.mat-icon-button,
.mat-button-focus-overlay {
    background: transparent !important;
}

.cdk-focused:focus-visible {
    background: #fdb831 !important;
}

.mat-tab-label-container .mat-tab-label {
    font-size: 16px;
    color: #4d4d4d;
    opacity: 1;
    font-weight: normal;
}

.mat-tab-label-active {
    opacity: 1 !important;
}

.mat-tab-label-active .mat-tab-label-content {
    opacity: 1 !important;
    color: #fdb831;
    font-weight: bold;
}

//Notification  css styles
.notify-count {
    width: 18px !important;
    height: 18px !important;
    font-size: 14px !important;
    border: 2px solid #ffffff;
}

//Toolbar css styles
.mat-toolbar {
    .mat-toolbar-row {
        .toolbar-btns {
            span {
                font-size: 14px;
                color: #4d4d4d;

                img {
                    margin-inline: 5px;
                }
            }
        }
    }
}

.cs-top-toolbar {
    width: calc(100% + 40px) !important;
    margin-inline: -20px !important;
    padding: 0px 20px !important;
}

//Search Bar css styles
.mat-toolbar {
    .search-bar {
        max-width: 320px !important;
        padding: 8px 30px 8px 40px !important;
        min-width: 200px;
        border-radius: 5px;
        border: 1px solid #bbb;
        font-size: 14px;
        height: calc(1.5em + 0.75rem + 10px);
    }

    .search-bar:focus-visible {
        border: 2px solid #fdb831;
        outline: none;
    }
}

//table css styles
.mat-table {
    border-collapse: collapse;

    thead {
        tr {
            height: 40px;
            text-align: center;
        }

        th {
            padding: 0 4px;
            text-align: center;
            position: relative;
            &::after{
              content:"";
              width: 100%;
              position: absolute;
              bottom: 0;
              left: 0;
              background-color: #cecece;
              height: 1px;
            }
        }

        .mat-header-cell {
            text-align: center !important;
            font-family: Roboto, "Helvetica Neue", sans-serif;
            font-size: 13px;
            color: #000000
        }
    }

    tbody {
        tr:not(.mat-header-row):hover {
            background: #fff5de;
            color: white;
            cursor: pointer;
            text-align: center;
        }

        .mat-cell {
            font-size: 14px;
            padding: 0 4px;
            word-break: break-word;
            text-align: center;
        }
    }
}

.tblOptions {
    position: absolute;
    right: 0;
    z-index: 1;
}

//Input styles---------------------------------->>

//Texbox styles------->>

.mat-form-field-infix {
    width: 280px !important;
    // min-width: 200px !important;
    // position: initial !important;
    // // max-width: 320px !important;
    // padding: 14px 5px !important;
    border-top: none !important;
    top: 5px;

    .mat-form-field-label-wrapper {
        .mat-form-field-label {
            mat-label {
                display: inline-flex;
                margin-bottom: 5px;
                background: #fff;
                padding: 5px 5px;
                position: relative;
                left: 10px;
            }
        }
    }
}

.mat-form-field-appearance-outline .mat-form-field-outline {
    color: #bbb !important;
}

.form-control {
    border: 1px solid #bbb !important;
}

.mat-select-value-text {
    position: relative;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: #fdb831 !important;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-outline-gap {
    border-top-color: unset !important;
}

.mat-form-field-wrapper {
    .mat-form-field-flex:hover {
        outline: none !important;
    }

    .mat-form-field-flex {
        .mat-form-field-outline:focus-visible {
            outline: none !important;
        }
    }

    .mat-form-field-flex:focus-visible {
        outline: none !important;
    }
}

.mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-color: #bbb;
}

.mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border-color: #bbb;
}

.mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-color: #bbb;
}

.form-control:focus {
    color: #4d4d4d !important;
    background-color: #fff;
    border-color: #f4b019 !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #fff5de;
}

// Textarea Styles------>>


// Input icons------>>
.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button,
.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
    display: inline-flex;
    justify-content: center;
    align-items: center !important;
    font-size: inherit;
    width: 2.5em;
    height: 2.5em;
}

.mat-icon {
    display: inline-flex !important;
    justify-content: center;
    width: 20px !important;
    align-content: center;
    align-items: center;

    // margin-inline: 4px;
    svg {
        padding: 1px;
    }
}

.mat-paginator-container {
    .mat-paginator-range-actions {
        .mat-icon-button {
            width: 20px;

            .mat-button-wrapper {
                .mat-paginator-icon {
                    width: 20px;
                }
            }
            .mat-button-ripple-round {
                width: 20px;
                height: 20px;
                top: 11px;
            }
        }
    }
}

.mat-tab-label-content {
    .mat-icon {
        display: inline-flex !important;
        justify-content: center;
        width: 36px !important;
        align-content: center;
        align-items: center;
        margin-inline: 4px;

        svg {
            padding: 7px;
        }
    }
}

.icon {
    width: 18px !important;
    margin-inline: 10px;
}

.icon-tab {
    width: 18px !important;
    margin-inline: 10px;
}

.icon-small {
    width: 12px !important;
}

//Dropdown styles------->>
.mat-option.mat-active {
    background: #fff5de;
    color: #f4b019;
    font-weight: bold;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #fdb831;
    background: #fff5de !important;
}

//Buttons styles
.cs-primary-btn {
    background: #f4b019;
    box-shadow: none !important;
}

.cs-secondary-btn {
    box-shadow: none !important;
    color: #666666 !important;
}

.cs-txt-color-btn {
    box-shadow: none !important;
    border: 1px solid #bbb !important;
    color: #fdb831 !important;
}

.cs-attachement-btn {
    width: 60px;
    height: 60px;
}

.cs-primary-btn:focus,
.cs-secondary-btn:focus {
    outline: none;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%) !important;
}

.btn-add-files {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    border: 1px solid #bbb;
}

button:focus {
    background: #fff5de;
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
    outline: none;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #fff5de;
}

.mat-stroked-button:not(.mat-button-disabled) {
    border-color: #bbb !important;
}

// .mat-raised-button:not([class*=mat-elevation-z]) {
//     box-shadow: none !important;
// }

//Dialog box styles

.mat-dialog-container {
    display: block;
    padding: 40px !important;
    border-radius: 10px !important;
    box-sizing: border-box;
    overflow: auto;
    outline: 0;
    width: 100%;
    height: 100%;
    min-height: inherit;
    max-height: inherit;
}

//Checkbox styles

.mat-checkbox {
    margin-inline: 20px;
    padding-top: 8px;
}

// Page Title styles

h1.main-title {
    font-size: 20px !important;
    font-weight: 500 !important;
    text-align: initial;
}

h2.sub-title {
    font-size: 16px !important;
    font-weight: 500 !important;
}

// Modal Box styles

.mat-menu-panel.top-right-user-menu {
    width: 100%;
    max-width: 420px !important;
    padding: 10px;
    min-width: 380px !important;
    height: auto;
    overflow: hidden;
}

// .cdk-overlay-pane {
//  height: 95% !important;
// }


.mat-tooltip-panel {
    height: auto !important;
}

.mat-tab-label-active {
    .mat-tab-label-content {
        img {
            filter: invert(76%) sepia(38%) saturate(7466%) hue-rotate(4deg) brightness(153%) contrast(126%);        }
    }
}

.mat-tab-label-active .mat-tab-label-content .mat-icon svg polyline {
    opacity: 1 !important;
    stroke: #fdb831;
    font-weight: bold;
}

.mat-tab-label-active .mat-tab-label-content .mat-icon svg line {
    opacity: 1 !important;
    stroke: #fdb831;
    font-weight: bold;
}

.transparent-dialog>mat-dialog-container {
    background: rgba(0, 0, 0, 0);
    padding: 0px !important;
    box-shadow: none;
}

// Paginator styles

.mat-paginator-page-size-label {
    margin: 0 4px;
    position: relative;
    top: 4px;
}

// Scrollbar styles

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #eeeeee;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #bbb;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #aaa;
}

.direction-ar{
    direction: rtl;
}

.direction-en{
    direction: ltr;
}

.onArabic{
    left: auto !important
}

.specialIcon{
    position: relative;

}

.mat-sort-header-container{
    justify-content: center;
}
mat-select.mat-select-disabled {
  background-color: #e9ecef !important;
  opacity: 1;
  color: #666666;
}
label.required:after{
  content : '*';
  color : red;
}
.font-size-input-6{
  font-size: 15px;
}
.font-size-input-7{
  font-size: 14px;
}
.font-size-input-8{
  font-size: 13px;
}
td{
  text-align: start !important;
}
th div,th mat-checkbox{
  display: flex !important;
  white-space: nowrap;
  justify-content: start !important;
}
.fs-7{
  font-size: 0.8rem !important
}
.fs-8{
  font-size: 0.6rem !important
}
.mat-toolbar .search-bar{
  padding: 8px 30px 8px 45px !important;
}
.tbl-container{
  position: relative;
  z-index: 0;
  height: 62vh;
  overflow: auto;
  /* width */
&::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
&::-webkit-scrollbar-track {
  background: #eeeeee;
}

/* Handle */
&::-webkit-scrollbar-thumb {
  background: #bbb;
}

/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}
}

.tf-v1-sidetab {
    display: none !important;
}
