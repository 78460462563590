@import "~@angular/material/theming";
@include mat-core();

$custom-primary-scheme: (
    50 : #fff6e6,
    100 : #feeac1,
    200 : #fedc98,
    300 : #fecd6f,
    400 : #fdc350,
    500 : #fdb831,
    600 : #fdb12c,
    700 : #fca825,
    800 : #fca01f,
    900 : #fc9113,
    A100 : #ffffff,
    A200 : #fffbf6,
    A400 : #ffe2c3,
    A700 : #ffd5aa,
    contrast: (50: $black-87-opacity,
        100: $black-87-opacity,
        200: $black-87-opacity,
        300: $black-87-opacity,
        400: $black-87-opacity,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: $black-87-opacity,
        A200: $black-87-opacity,
        A400: $black-87-opacity,
        A700: white,
    )
);


$custom-accent-scheme: (
    50 : #f7f7f7,
    100 : #ebebeb,
    200 : #dedede,
    300 : #d1d1d1,
    400 : #c7c7c7,
    500 : #bdbdbd,
    600 : #b7b7b7,
    700 : #aeaeae,
    800 : #a6a6a6,
    900 : #989898,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffd4d4,
    A700 : #ffbbbb,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


$my-custom-primary: mat-palette($custom-primary-scheme);
$my-custom-accent: mat-palette($custom-accent-scheme, 100, 500, A100);
$my-custom-warn: mat-palette($mat-red);
$my-custom-theme: mat-light-theme($my-custom-primary, $my-custom-accent, $my-custom-warn);
@include angular-material-theme($my-custom-theme);


// global styling
.icon {
    width: 20px;
    margin-inline: 10px;
}

.icon-white {
    filter: brightness(0) invert(1); // white color
}

button:focus {
    outline: none;
}

button {
    box-shadow: none !important;
}

.mat-radio-ripple {
    display: none;
}

ngx-intl-tel-input input {
    margin-bottom: 20px;
    padding: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #c7cace;
    border-radius: 4px;
    width: 300px;
}

.block-ui {
    position: absolute !important;
    top: 0;
    right: 0;
    left: 0;
    height: 100% !important;
    width: 100% !important;
    overflow: auto;
    background-color: transparent !important;
    cursor: wait;

    .block-ui-inner-div {
        margin: auto;
        height: 200px;
        width: 200px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: gray;
        opacity: 0.5;
        border-radius: 8px;
        text-align: center;

        mat-spinner {
            top: 10px;
            margin: auto;
        }

        .spinner-txt {
            color: white;
            margin-top: 40px;
        }
    }
}

.upload-progress {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
    background-color: transparent;
    cursor: wait;

    .inner-div {
        margin: auto;
        height: 300px;
        width: 300px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(220,220,220,0.5);
        padding: 20px;
        border-radius: 8px;
        text-align: center;
    }
}

.select-custom-outline {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
}
